import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'

const SubMenuUl = styled.ul`
  &.desktop {
    grid-gap: 0 1rem;
    max-width: 650px;
    outline: 1px solid #00000010;
    ${tw`hidden bg-white rounded-md absolute shadow-lg`};
  }
  &.desktop:first-of-type {
    max-width: 750px;
  }
  &.desktop:first-of-type .listItem:not(.largeMenu) {
    max-width: 100%;
  }
  &.desktop:hover {
    ${tw`grid`}
  }
  &.desktop.largeMenuHolder {
    grid-template-columns: auto 1fr;
  }
  &.desktop .largeMenu {
    max-width: 250px;
  }
  &.desktop .largeMenu + .listItem > a {
    ${tw`items-end`}
  }
  &.desktop li:first-of-type a {
    ${tw`items-end`}
  }
  &.desktop .largeMenu:first-of-type a {
    ${tw`items-start`}
  }
  &.desktop .largeMenu:not(.largeMenu + .largeMenu) {
    border-radius: 5px 0 0 5px;
    ${tw`pb-5 border-r-2 border-sand`} !important;
  }
  &.desktop .largeMenu:not(.largeMenu + .largeMenu) a {
  }
  &.desktop:last-of-type,
  &.desktop:nth-last-of-type(2),
  &.desktop:nth-last-of-type(3) {
    ${tw`right-0`}
  }
  &.desktop .listItem a {
    ${tw`items-center`}
  }
  &.desktop .listItem.largeMenu a {
    ${tw`flex-col`}
  }
  &.desktop li {
    max-width: 400px;
    ${tw`pl-4 pr-4`}
  }
  &.desktop li:last-of-type {
    ${tw`pb-5`} !important;
  }
  &.desktop .listItem a {
    ${tw`px-4 py-2 rounded-md font-bold text-navy flex flex-row`}
  }
  &.desktop .largeMenuTitle {
    color: #999;
    ${tw`text-sm mb-5 mt-6 w-full block uppercase pointer-events-none`}
  }
  &.desktop .largeMenu:not(.largeMenu + .largeMenu) .largeMenuTitle {
    color: #999;
  }
  &.desktop .titleMenu {
    ${tw`flex flex-col justify-center flex-1`}
  }
  &.mobile {
    ${tw`hidden`}
  }
  &.mobile.show {
    ${tw`inline-block`}
  }
  &.mobile.show:first-of-type {
    ${tw`flex flex-wrap`}
  }
  &.mobile.show:first-of-type .largeMenu {
    ${tw`w-full border-b-2 border-sand mb-4`}
  }
  &.mobile.show:first-of-type .produkt {
    ${tw`w-1/2 text-center`}
  }
`

const isPartiallyActiveExact = (props) => {
  const currentLocation = props.location.pathname
  const toProp = props.href
  const regex = new RegExp(`${toProp}/`, 'i')
  if (regex.test(currentLocation) || currentLocation === toProp) {
    return { className: 'active' }
  }
}

const StyledLink = styled(Link)`
  ${(props) =>
    !props.mobilemenustate &&
    tw`px-4 py-2 rounded-md font-bold text-navy flex flex-col`}
`

export default function SubMenu({
  subMenuItems,
  productCategories,
  mobileMenuState,
  mobileSubMenu,
  slug,
}) {
  // const truncateString = (string) => string.slice(0, 300) + '...'

  return (
    <>
      {(subMenuItems.length !== 0 || productCategories !== null) && (
        <SubMenuUl
          className={`subMenu ${mobileMenuState ? 'mobile' : 'desktop'} ${
            mobileSubMenu === slug ? 'show' : ''
          } ${
            subMenuItems.map((e) => e['largeMenu']).includes(true) &&
            'largeMenuHolder'
          }`}
        >
          {subMenuItems.length !== 0 &&
            subMenuItems.map((element) => {
              return (
                element !== null &&
                !element.hide && (
                  <li
                    className={`listItem ${
                      element.largeMenu ? 'largeMenu' : ''
                    }`}
                    key={element.slug}
                    css={[
                      element.largeMenu &&
                        css`
                          grid-row: 1 / span
                            ${(subMenuItems?.length || 0) +
                            (productCategories?.length || 0) +
                            1}; /* calculate number of items plus one */
                        `,
                    ]}
                  >
                    {!mobileMenuState && element.menuCategoryTitle && (
                      <span
                        css={[
                          css`
                            color: #999;
                          `,
                          tw`text-sm uppercase block font-bold pointer-events-none mt-6 mb-4`,
                          element.menuIcon[0]
                            ? css`
                                margin-left: 4.5rem;
                              `
                            : css`
                                margin-left: 1rem;
                              `,
                        ]}
                      >
                        {element.menuCategoryTitle}
                      </span>
                    )}
                    <StyledLink
                      mobilemenustate={
                        mobileMenuState ? true.toString() : undefined
                      }
                      getProps={isPartiallyActiveExact}
                      to={`/${element.uri}`}
                    >
                      {!mobileMenuState && element.largeMenu && (
                        <div className="largeMenuTitle">{element.title}</div>
                      )}
                      {!mobileMenuState &&
                        (element.largeMenu || element.menuIcon[0]) &&
                        (element.menuIcon[0]?.localFile?.extension === 'svg' ? (
                          <img
                            src={element.menuIcon[0]?.url || null}
                            alt={element.menuIcon[0]?.title}
                            css={css`
                              height: 35px;
                              width: 35px;
                              margin-right: 1rem;
                            `}
                          />
                        ) : (
                          element.menuIcon[0]?.localFile?.childImageSharp
                            ?.gatsbyImageData && (
                            <GatsbyImage
                              image={
                                element.menuIcon[0]?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                              objectFit={!element.largeMenu && 'contain'}
                              alt={element.menuIcon[0]?.title}
                              css={[
                                element.largeMenu && tw`mb-5`,
                                css`
                                  ${element.largeMenu
                                    ? 'max-width: 200px;'
                                    : 'height: 40px; width: 40px; margin-right: 1rem;'}
                                `,
                              ]}
                            />
                          )
                        ))}
                      <div css={tw`flex flex-col justify-center flex-1`}>
                        <div className="titleMenu">
                          <span
                            css={[
                              tw`uppercase leading-none mb-2`,
                              mobileMenuState &&
                                tw`text-navy text-lg font-bold mt-8 inline-block`,
                              !element.largeMenu && tw`uppercase`,
                            ]}
                          >
                            {element.title}
                          </span>
                          {!mobileMenuState && (
                            <span
                              css={tw`text-xs font-light leading-snug pb-1`}
                            >
                              {element.menuDescription}
                            </span>
                          )}
                        </div>
                      </div>
                    </StyledLink>
                  </li>
                )
              )
            })}
          {productCategories !== null &&
            productCategories.map((element) => {
              return (
                element !== null && (
                  <li className={'listItem produkt'} key={element.slug}>
                    <StyledLink
                      mobilemenustate={
                        mobileMenuState ? true.toString() : undefined
                      }
                      getProps={isPartiallyActiveExact}
                      to={`/${element.uri}`}
                    >
                      {element.menuIcon[0]?.localFile?.childImageSharp
                        ?.gatsbyImageData && (
                        <GatsbyImage
                          width="60"
                          height="60"
                          image={
                            element.menuIcon[0]?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          objectFit={!element.largeMenu && 'contain'}
                          alt={element.menuIcon[0]?.title}
                          css={tw`mr-5`}
                        />
                      )}
                      <div css={tw`flex flex-col justify-center flex-1`}>
                        {!mobileMenuState && element.menuCategoryTitle && (
                          <span
                            css={[
                              css`
                                color: #999;
                                text-transform: uppercase;
                                pointer-events: none;
                              `,
                              tw`text-sm mb-5 mt-6`,
                            ]}
                          >
                            {element.menuCategoryTitle}
                          </span>
                        )}
                        <div className="titleMenu">
                          <span
                            css={[
                              tw`uppercase`,
                              mobileMenuState &&
                                tw`text-navy mt-1 mb-2 text-lg font-bold inline-block`,
                            ]}
                          >
                            {element.title}
                          </span>
                          {!mobileMenuState && (
                            <span
                              css={tw`text-xs font-light leading-snug pb-1`}
                            >
                              {element.menuDescription}
                            </span>
                          )}
                        </div>
                      </div>
                    </StyledLink>
                  </li>
                )
              )
            })}
        </SubMenuUl>
      )}
    </>
  )
}
